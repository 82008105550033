
import ShapeBgCard from './ShapeBgCard.vue'
export default {
  name: 'ShapeBgCardBlock',
  components: {
	ShapeBgCard
  },
  props: {
    blockOptions: {
      type: Object
    }
  }
}
